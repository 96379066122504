var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('container',{attrs:{"title":_vm.$t('companyProfile')}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"btn_box clearfix"},[(_vm.inspectButton('mem:bom:mdi'))?_c('el-button',{staticClass:"btn fr",staticStyle:{"width":"80px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.edit}},[_vm._v(_vm._s(_vm.$t("modify")))]):_vm._e()],1),_c('div',{staticClass:"base_msg"},[_c('div',{staticClass:"s_box left",class:{
            span_width_en: _vm.LOCALE == 'en',
            span_width_zh: _vm.LOCALE == 'zh',
            p_width_en: _vm.LOCALE == 'en',
            p_width_zh: _vm.LOCALE == 'zh',
          }},[_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("companyLogo"))+"：")]),_c('img',{attrs:{"src":_vm.infoForm.logo ? _vm.infoForm.logo : _vm.DEFAULT_LOGO,"alt":""}})]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("WCompanyName"))+"：")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.infoForm.name_en,_vm.infoForm.name_zh, _vm.LOCALE))+" ")])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("registered"))+"：")]),_c('p',[_vm._v(_vm._s(_vm._f("textFormat")(_vm.infoForm.registered_capital)))])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("established"))+"：")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("textFormat")(_vm.infoForm.found_year && _vm.infoForm.found_year))+" ")])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("contcat"))+"：")]),_c('p',[_vm._v(_vm._s(_vm._f("textFormat")(_vm.infoForm.contacts)))])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("contactPhone"))+"：")]),_c('p',[_vm._v(" "+_vm._s(_vm.infoForm.area_code)+" "+_vm._s(_vm.infoForm.telephone)+" ")])])]),_c('div',{staticClass:"s_box right",class:{
            span_width_en: _vm.LOCALE == 'en',
            span_width_zh: _vm.LOCALE == 'zh',
            p_width_en: _vm.LOCALE == 'en',
            p_width_zh: _vm.LOCALE == 'zh',
          }},[_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("location"))+"：")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("countryCityFormat")(_vm.infoForm.country, _vm.infoForm.region_en, _vm.infoForm.region_zh, _vm.LOCALE ))+" ")])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("enterprisescale"))+"：")]),_c('p',[_vm._v(_vm._s(_vm._f("staffSizeFormat")(_vm.infoForm.scale,_vm.LOCALE)))])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("companyWebsite"))+"：")]),_c('p',[_vm._v(_vm._s(_vm._f("textFormat")(_vm.infoForm.website)))])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("email"))+"：")]),_c('p',[_vm._v(_vm._s(_vm._f("textFormat")(_vm.infoForm.email)))])]),_c('div',[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("companyAddress"))+"：")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("priorFormat")(_vm.infoForm.address_en,_vm.infoForm.address_zh, _vm.LOCALE))+" ")])])])]),_c('div',{class:{
          span_width_en: _vm.LOCALE == 'en',
          span_width_zh: _vm.LOCALE == 'zh',
        }},[_c('div',{staticClass:"profile"},[_c('span',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t("companyDesc"))+"：")]),_c('pre',[_vm._v(_vm._s(_vm._f("priorFormat")(_vm.infoForm.profile_en,_vm.infoForm.profile_zh, _vm.LOCALE)))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }