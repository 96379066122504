<template>
  <div v-if="has_live">
    <container :title="$t('boothActive')">
      <div class="btns">
        <el-button type="primary"   v-if="inspectButton('mem:bom:mdi')" size="small" @click="addAgaent">{{
          $t("addActive")
        }}</el-button>
      </div>
      <div class="dataList">
        <el-table :data="list" height="370" style="width: 100%">
          <el-table-column :label="$t('time')" width="240">
            <template slot-scope="scope">
              {{ scope.row.day | secondFormat("LL") }}
              {{ scope.row.start_time | secondFormat("T") }} -
              {{ scope.row.end_time | secondFormat("T") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('meetingTitleAll')" width="240">
            <template slot-scope="scope">
              {{ scope.row.issue_en | priorFormat(scope.row.issue_zh, LOCALE) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('type')">
            <template slot-scope="scope">
              <span v-if="scope.row.agenda_type == 1">{{$t('Livebroadcast')}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('Moderator')">
            <template slot-scope="scope">
              {{ scope.row.host }}
            </template>
          </el-table-column>

          <el-table-column prop="address" width="100" :label="$t('handle')">
            <template slot-scope="scope">
              <el-button type="text" slot="reference"  v-if="inspectButton('mem:bom:mdi')" @click="edit(scope.row)">{{
                $t("edit")
              }}</el-button>
              <el-button type="text" slot="reference"  v-if="inspectButton('mem:bom:mdi')" @click="deleteFile(scope.row.id)">{{
                $t("delete")
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- :before-close="handleClose" -->
      <el-drawer
        :title="$t('setActiveOrChoose')"
        size="500px"
        @close="clearForm"
        :visible.sync="drawer"
      >
        <div class="agaentContent">
          <el-form
            :show-message="false"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item :label="$t('activeDate')" prop="time" required>
              <el-date-picker
                size="small"
                class="time"
                :picker-options="pickerOptions"
                v-model="ruleForm.time"
                type="date"
                :placeholder="$t('meetingChangeTimePlace')"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('activeTimes')" prop="date" required>
              <el-time-picker
                is-range
                size="small"
                format="HH:mm"
                v-model="ruleForm.date"
                range-separator="-"
                class="date"
                :start-placeholder="$t('beginTime')"
                :end-placeholder="$t('endTime')"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item :label="$t('meetingTitleAll')" required>
              <el-input
                v-model="ruleForm.name_zh"
                size="small"
                :placeholder="$t('themezh')"
              ></el-input>
              <el-input
                style="margin-top: 13px"
                v-model="ruleForm.name_en"
                size="small"
                :placeholder="$t('themeen')"
              ></el-input>
              <p class="tips">*{{ $t("oneMustInter") }}</p>
            </el-form-item>
            <el-form-item :label="$t('type')" prop="type" required>
              <el-select
                v-model="ruleForm.type"
                class="type"
                size="small"
                :placeholder="$t('pleaseSelect')"
              >
                <el-option
                  v-for="item in typeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="ruleForm.type == 1">
              <el-form-item :label="$t('liveChannel')" prop="liveLine" required>
                <el-select
                  v-model="ruleForm.liveLine"
                  class="type"
                  size="small"
                  :placeholder="$t('pleaseSelect')"
                  @change="lineChange"
                >
                  <el-option
                    v-for="item in liveLineOption"
                    :key="item.live_id"
                    :label="item.channel_name"
                    :value="item.live_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Moderator')" prop="liveLine">
                <el-input v-model="ruleForm.host" size="small" placeholder=""></el-input>
              </el-form-item>
              <el-form-item :label="$t('meetingUserAll')" prop="guest">
                <el-input v-model="ruleForm.guest" size="small" placeholder=""></el-input>
              </el-form-item>
            </div>

            <el-form-item :label="$t('picManageTab')" prop="" required>
              <div class="maskImg">
                <div>
                  <fileUpload
                    ref="uploadComponents"
                    :accept="'.jpg,.jpeg,.png'"
                    :autoUpload="true"
                    @uploadCompleteOSS="uploadSuccessPc"
                  >
                    <el-image
                      v-if="ruleForm.bannerPc"
                      style="width: 124px; height: 86px"
                      :src="ruleForm.bannerPc"
                      :fit="'contain'"
                    ></el-image>
                    <div class="addDrawer" v-else>
                      <i class="el-icon-plus"></i>
                    </div>
                  </fileUpload>
                  <p>{{ $t("pcImages") }}</p>
                  <p class="tips">{{ $t("pleaseUpImg", { size: "750 x 1334" }) }}</p>
                </div>

                <div>
                  <fileUpload
                    ref="uploadComponents"
                    :accept="'.jpg,.jpeg,.png'"
                    :autoUpload="true"
                    @uploadCompleteOSS="uploadSuccessMobile"
                  >
                    <el-image
                      v-if="ruleForm.bannerMobile"
                      style="width: 124px; height: 86px"
                      :src="ruleForm.bannerMobile"
                      :fit="'contain'"
                    ></el-image>
                    <div class="addDrawer" v-else>
                      <i class="el-icon-plus"></i>
                    </div>
                  </fileUpload>
                  <p>{{ $t("MobileImage") }}</p>
                  <p class="tips">{{ $t("pleaseUpImg", { size: "750 x 1334" }) }}</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              v-if="ruleForm.type == 3"
              :label="$t('AgendaIntroduce')"
              prop="baseInfo"
            >
              <el-input
                type="textarea"
                rows="4"
                v-model="ruleForm.baseInfo"
                size="small"
                placeholder=""
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="earn"></div>
        </div>
        <div class="clearfix drawerFooter">
          <el-button
            type="primary"
            size="small"
            class="fr"
            style="margin-left: 16px; width: 80px"
            :loading="btnloading"
            @click="submitForm('ruleForm')"
            >{{ $t("confirm") }}</el-button
          >
          <el-button size="small" class="fr" @click="drawer=false" style="width: 80px">{{
            $t("cancel")
          }}</el-button>
        </div>
      </el-drawer>
    </container>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import boothMixin from "./mixin";
import container from "./container";
export default {
  mixins: [boothMixin],
  props: ["booth_id"],
  data() {
    return {
      has_live:false,
      drawer: false,
      btnloading: false,
      typeOption: "",
      liveLineOption: [],
      hostOption: [],
      ruleForm: {
        time: "",
        date: "",
        name_zh: "",
        name_en: "",
        type: "",
        liveLine: "",
        host: "",
        guest: "",
        baseInfo: "",
        bannerPc: "",
        bannerMobile: "",
      },
      rules: {},
      list: [],
      pickerOptions:null,
    };
  },
  components: { container, fileUpload },
  mounted() {
    this.typeOption = [
      {
        value: 1,
        label: this.$t("meetingZhibo"),
      },
    ];
  },
  watch: {
    async booth_id(nv) {
      let data = await this.$store.dispatch("baseConsole/getLineList", {
        booth_id: nv,
      });
       let res = await this.$store.dispatch("baseConsole/getMeetingMsg", {
        meeting_id:this.MEETING_ID,
      });
      this.pickerOptions={
         disabledDate: (time) => {
          
          // 如果函数里处理的数据比较麻烦,也可以单独放在一个函数里,避免data数据太臃肿
          return !(res.data.start_time*1000 <=time.getTime() && time.getTime()< res.data.end_time*1000)
        }
      }
      this.liveLineOption = data.data;
      this.getList();
    },
    async "ruleForm.type"(nv) {
    },
  },
  methods: {
    deleteFile(id) {
      this.$confirm(`${this.$t("confirmDelete")}?`, this.$t("tipsTitle"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let data = await this.$store.dispatch("baseConsole/boothAgaent", {
            booth_agenda_id: id,
            deleted: 1,
          });
          if (data.success) {
            this.getList();
          }
        })
        .catch(() => {});
    },
    clearForm() {
      this.$refs["ruleForm"].resetFields();

      this.ruleForm = {
        time: "",
        date: "",
        name_zh: "",
        name_en: "",
        type: "",
        liveLine: "",
        host: "",
        guest: "",
        baseInfo: "",
        bannerPc: "",
        bannerMobile: "",
      };
    },
    edit(e) {
      this.ruleForm = {
        time: new Date(e.day * 1000),
        date: [new Date(e.start_time * 1000), new Date(e.end_time * 1000)],
        name_zh: e.issue_zh,
        name_en: e.issue_en,
        type: e.agenda_type,
        liveLine: e.live_id,
        host: e.host,
        guest: e.guests_id,
        baseInfo: e.introduce,
        bannerPc: e.live_img,
        bannerMobile: e.live_img_mobile,
        id: e.id,
      };
      this.drawer = true;
    },
    async getList() {
      let data = await this.$store.dispatch("baseConsole/getAgaentList", {
        nopage: 1,
        meeting_booth_id: this.booth_id,
        user_id: this.USER_ID,
      });
      this.initComplete();
      if (data.success) {
        this.list = data.data;
      }
        let res = await this.$store.dispatch("baseConsole/getLiveHas", {
        booth_id:this.booth_id,
      });
      this.has_live = res.data.has_live
     
    },
    uploadSuccessPc(e) {
      this.ruleForm.bannerPc = e.url;
    },
    uploadSuccessMobile(e) {
      this.ruleForm.bannerMobile = e.url;
    },
    lineChange(e) {
      let data = this.liveLineOption.find((res) => {
        return res.live_id == e;
      });
      this.ruleForm.host = data.host_name;
    },
    addAgaent() {
      this.drawer = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {

        if (valid) {
          if (!this.ruleForm.name_zh && !this.ruleForm.name_en) {
            this.$message({
              message: this.$t("topicMustInterOne"),
              type: "warning",
            });
            return;
          }
          if (!this.ruleForm.bannerPc) {
            this.$message({
              message: this.$t("pcImage"),
              type: "warning",
            });
            return;
          }
          if (!this.ruleForm.bannerMobile) {
            this.$message({
              message: this.$t("mobileImage"),
              type: "warning",
            });
            return;
          }
          this.btnloading = true;
          let time = this.$moment(this.ruleForm.time).format("YYYY-MM-DD");
          let start = this.$moment(this.ruleForm.date[0]).format("YYYY-MM-DD HH:mm:ss");
          let newStar = start.split(" ");
          start = time + " " + newStar[1];
          let end = this.$moment(this.ruleForm.date[1]).format("YYYY-MM-DD HH:mm:ss");
          let newEnd = end.split(" ");
          end = time + " " + newEnd[1];
          //  this.list.forEach(ee=>{
          //   if(ee.day == this.$moment(this.ruleForm.time).unix()){
          //     if(ee.start_time<=this.$moment(start).unix() && ee.end_time >= this.$moment(end).unix()){
          //        this.$message({
          //           message: "此时间段内已有议程",
          //           type: "warning",
          //         });
          //         return;
          //     }
          //   }
          // })
          let parmas = {
            meeting_id: this.$store.state.meeting_id,
            booth_id: this.booth_id,
            day: this.$moment(start).unix(),
            start_time: this.$moment(start).unix(),
            end_time: this.$moment(end).unix(),
            issue_zh: this.ruleForm.name_zh,
            issue_en: this.ruleForm.name_en,
            agenda_type: this.ruleForm.type,
            guests: this.ruleForm.guest,
            host: this.ruleForm.host,
            live_id: this.ruleForm.liveLine,
            live_img: this.ruleForm.bannerPc,
            live_img_mobile: this.ruleForm.bannerMobile,
            introduce: this.ruleForm.baseInfo,
            _NOCLEAR:true
          };
          if (this.ruleForm.id) {
            parmas.booth_agenda_id = this.ruleForm.id;
          }
          let data = await this.$store.dispatch("baseConsole/boothAgaent", parmas);
          this.btnloading = false;
          if (data.success) {
            this.drawer = false;
            this.getList();
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.earn {
  width: 100%;
  height: 100px;
}
.drawerFooter {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px;
  background: #FFF;
}
/deep/.el-drawer__body {
  overflow-y: auto;
}
.btns {
  display: flex;
  padding: 0 32px;
  justify-content: flex-end;
}
.demo-ruleForm {
  padding-right: 30px;
  .time {
    width: 100%;
  }
  .date {
    width: 100%;
  }
  .tips {
    color: #999;
    font-size: 12px;
    line-height: 20px;
  }
  .type {
    width: 100%;
  }
  .addDrawer {
    width: 124px;
    height: 86px;
    background: #ffffff;
    border: 1px dashed #c8cdd0;
    margin-left: 16px;
    position: relative;
    i {
      color: #c8cdd0;
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .maskImg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
}
</style>
