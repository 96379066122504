<template>
  <div>
    <container :title="$t('companyProfile')">
      <div class="content">
        <div class="btn_box clearfix">
          <el-button
            type="primary"
            size="small"
            style="width: 80px"
            class="btn fr"
            v-if="inspectButton('mem:bom:mdi')"
            @click="edit"
            >{{ $t("modify") }}</el-button
          >
        </div>
        <div class="base_msg">
          <div
            class="s_box left"
            :class="{
              span_width_en: LOCALE == 'en',
              span_width_zh: LOCALE == 'zh',
              p_width_en: LOCALE == 'en',
              p_width_zh: LOCALE == 'zh',
            }"
          >
            <div>
              <span class="lable">{{ $t("companyLogo") }}：</span>
              <img :src="infoForm.logo ? infoForm.logo : DEFAULT_LOGO" alt="" />
            </div>
            <div>
              <span class="lable">{{ $t("WCompanyName") }}：</span>
              <p>
                {{ infoForm.name_en | priorFormat(infoForm.name_zh, LOCALE) }}
              </p>
            </div>
            <div>
              <span class="lable">{{ $t("registered") }}：</span>
              <p>{{ infoForm.registered_capital | textFormat }}</p>
            </div>
            <div>
              <span class="lable">{{ $t("established") }}：</span>
              <p>
                {{ infoForm.found_year && infoForm.found_year | textFormat }}
              </p>
            </div>
            <div>
              <span class="lable">{{ $t("contcat") }}：</span>
              <p>{{ infoForm.contacts | textFormat }}</p>
            </div>
            <div>
              <span class="lable">{{ $t("contactPhone") }}：</span>
              <p>
                {{ infoForm.area_code }}
                {{ infoForm.telephone }}
              </p>
            </div>
          </div>
          <div
            class="s_box right"
            :class="{
              span_width_en: LOCALE == 'en',
              span_width_zh: LOCALE == 'zh',
              p_width_en: LOCALE == 'en',
              p_width_zh: LOCALE == 'zh',
            }"
          >
            <div>
              <span class="lable">{{ $t("location") }}：</span>
              <p>
                {{
                  infoForm.country
                    | countryCityFormat(
                      infoForm.region_en,
                      infoForm.region_zh,
                      LOCALE
                    )
                }}
              </p>
            </div>
            <div>
              <span class="lable">{{ $t("enterprisescale") }}：</span>
              <p>{{ infoForm.scale | staffSizeFormat(LOCALE) }}</p>
            </div>
            <div>
              <span class="lable">{{ $t("companyWebsite") }}：</span>
              <p>{{ infoForm.website | textFormat }}</p>
            </div>
            <div>
              <span class="lable">{{ $t("email") }}：</span>
              <p>{{ infoForm.email | textFormat }}</p>
            </div>
            <div>
              <span class="lable">{{ $t("companyAddress") }}：</span>
              <p>
                {{
                  infoForm.address_en | priorFormat(infoForm.address_zh, LOCALE)
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          :class="{
            span_width_en: LOCALE == 'en',
            span_width_zh: LOCALE == 'zh',
          }"
        >
          <div class="profile">
            <span class="lable">{{ $t("companyDesc") }}：</span>
            <pre>{{
              infoForm.profile_en | priorFormat(infoForm.profile_zh, LOCALE)
            }}</pre>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import container from "./container";
import boothMixin from "./mixin";
export default {
  mixins: [boothMixin],
  components: { container },
  data() {
    return {
      isExamine: false,
      infoForm: {},
    };
  },
  mounted() {
    this.getList();
    // if (JSON.stringify(this.USER_INFO) != {}) {
    //   // this.avatarImg = this.USER_INFO.avatar;

    //   this.infoForm = {
    //     name_zh: this.USER_INFO.name_zh,
    //     name_en: this.USER_INFO.name_en,
    //     sex: this.USER_INFO.sex,
    //     job_title_zh: this.USER_INFO.job_title_zh,
    //     job_title_en: this.USER_INFO.job_title_en,
    //     birthday: this.USER_INFO.birthday
    //       ? this.$moment(this.USER_INFO.birthday * 1000).format("YYYY-MM-DD")
    //       : "",
    //     linkedin: this.USER_INFO.linkedin,
    //     facebook: this.USER_INFO.facebook,
    //     skype: this.USER_INFO.skype,
    //     wechat: this.USER_INFO.wechat,
    //     qq: this.USER_INFO.qq,
    //     avatar: this.USER_INFO.avatar,
    //   };
    // }
  },
  methods: {
    async getList() {
      let companyInfo = await this.$store.dispatch(
        "baseConsole/company_getCompanyInfo",
        {
          company_id: this.USER_INFO.company_id,
        }
      );
      if (companyInfo.success) {
        this.infoForm = companyInfo.data;
      }
      this.initComplete();
    },
    edit() {
      this.$router.push({
        path: "/console/company/basic",
        // query: {
        //   parameter: this._encode({
        //     source: "/console/conference/caseCard/admin",
        //   }),
        // },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-bottom: 36px;
}
.span_width_en {
  div span {
    width: 150px;
  }
}
.span_width_zh {
  div span {
    width: 100px;
  }
}

.p_width_zh {
  div p {
    max-width: 400px;
  }
}
.p_width_en {
  div p {
    max-width: 350px;
  }
}
.base_msg {
  display: flex;

  .s_box {
    // padding-left: 10px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      width: 500px;
      padding-bottom: 16px;
      // span {
      //   width: 100px;
      //   font-size: 14px;
      //   color: #606266;
      // }
      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .right {
    padding-top: 115px;
    padding-bottom: 10px;
  }
}
.btn_box {
  padding: 8px 32px 24px;
}
.profile {
  width: 1000px !important;
  display: flex;
  padding-bottom: 16px;
  margin: -10px 32px 0;
}
.lable {
  // width: 150px;
  font-size: 14px;
  color: #606266;
}
pre {
  width: 90%;
  text-align: justify;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>