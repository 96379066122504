<template>
  <div class="baseBoothSetContainer">
    <div class="header" :class="{'hasLine':isOpen}">
      <div class="lineColor"></div>
      <div class="titleText fl">
        {{ title }}
      </div>
      <div class="handleBtn fr">
        <el-button type="text" v-if="!isOpen" @click="handleOpen"
          >{{ $t("open") }}<i class="el-icon-arrow-down el-icon--right"></i
        ></el-button>
        <el-button type="text" v-else @click="handleOpen"
          >{{ $t("packup") }}<i class="el-icon-arrow-up el-icon--right"></i
        ></el-button>
      </div>
    </div>
    <div class="slotContainer" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "模块标题",
    },
  },
  components: {},
  data() {
    return {
      isOpen: true,
    };
  },
  methods:{
      handleOpen(){
          this.isOpen = !this.isOpen
      }
  }
};
</script>

<style lang="less" scoped>
.baseBoothSetContainer {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  .header {
    width: 100%;
    height: 54px;
    background: #ffffff;
    line-height: 54px;
    position: relative;
    padding-left: 15px;
    padding-right: 20px;
    &.hasLine{
        border-bottom: 1px solid #C0C4CC;
    }
    .lineColor {
      width: 2px;
      height: 16px;
      background: #22d00e;
      position: absolute;
      top: 19px;
      left: 0px;
    }
  }
  .slotContainer {
    background: #fff;
    padding: 10px;
  }
}
</style>