<template>
  <div>
    <container :title="$t('seatsBooth')">
      <div class="seats">
        <div class="content">
          <div class="seatsTitle clearfix">
            <div class="fl">{{ $t("onlineSeats") }}</div>
            <div class="fr">
              <el-button
                type="primary"
                v-if="inspectButton('mem:bom:mdi')"
                size="small"
                class="btn fr"
                @click="addOnline"
                >{{ $t("addOnlineSeats") }}</el-button
              >
            </div>
          </div>
          <div class="onlineContent clearfix">
            <div
              class="onlineItem fl clearfix"
              v-for="(it, index) in onlineList"
              :key="index"
            >
              <div class="avatar fl">
                <el-image
                  style="border-radius: 50%"
                  fit="cover"
                  :src="it.user2_avatar ? it.user2_avatar : DEFAULT_AVATAR"
                ></el-image>
              </div>
              <div class="fl info">
                <div class="textOverflow">
                  {{ it.user_name_en | priorFormat(it.user_name_zh, LOCALE) }}
                </div>
                <div class="textOverflow">
                  {{
                    it.user2_job_title_en
                      | priorFormat(it.user2_job_title_zh, LOCALE)
                  }}
                </div>
              </div>
              <div
                class="delete cursor"
                @click="deleteVideo(it)"
                v-if="inspectButton('mem:bom:mdi')"
              >
                {{ $t("delete") }}
              </div>
            </div>
            <div class="nodatas" v-if="onlineList.length == 0">
              {{ $t("nodata") }}
            </div>
          </div>
        </div>
        <div class="content">
          <div class="seatsTitle clearfix">
            <div class="fl">{{ $t("offlineSeats") }}</div>
            <div class="fr">
              <el-button
                v-if="inspectButton('mem:bom:mdi')"
                type="primary"
                size="small"
                class="btn fr"
                @click="addOffline"
                >{{ $t("addOfflineSeats") }}</el-button
              >
            </div>
          </div>
          <div class="onlineContent clearfix">
            <div
              class="onlineItem fl"
              v-for="(it, index) in offlineList"
              :key="index"
            >
              <div class="avatar fl">
                <el-image
                  style="border-radius: 50%"
                  fit="cover"
                  :src="it.user2_avatar ? it.user2_avatar : DEFAULT_AVATAR"
                ></el-image>
              </div>
              <div class="fl info">
                <div class="textOverflow">
                  {{ it.user_name_en | priorFormat(it.user_name_zh, LOCALE) }}
                </div>
                <div class="textOverflow">
                  {{
                    it.user2_job_title_en
                      | priorFormat(it.user2_job_title_zh, LOCALE)
                  }}
                </div>
              </div>
              <div
                class="delete cursor"
                @click="deleteVideo(it)"
                v-if="inspectButton('mem:bom:mdi')"
              >
                {{ $t("delete") }}
              </div>
            </div>
            <div class="nodatas" v-if="offlineList.length == 0">
              {{ $t("nodata") }}
            </div>
          </div>
        </div>
      </div>
    </container>
    <el-drawer
      size="500px"
      :title="drawerTitle"
      :visible.sync="drawerShow"
      direction="rtl"
    >
      <div class="drawerData">
        <el-table
          :data="attendList"
          style="width: 100%"
          height="100"
          v-tableHeight="{ bottomOffset: 95 }"
          :tableLoading="oneLoading"
        >
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-checkbox
                :value="scope.row.isCheck"
                @change="checkValue(scope)"
                :disabled="scope.row.isdisabled"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="date" :label="$t('participant')">
            <template slot-scope="scope">
              <div class="clearfix">
                <div class="avatarTable fl">
                  <el-image
                    fit="cover"
                    style="border-radius: 50%"
                    :src="scope.row.avatar ? scope.row.avatar : DEFAULT_AVATAR"
                  ></el-image>
                </div>
                <div class="fl infoTable">
                  <div class="textOverflow">
                    {{
                      scope.row.name_en | priorFormat(scope.row.name_zh, LOCALE)
                    }}
                  </div>
                  <div class="textOverflow">
                    {{
                      scope.row.job_title_en
                        | priorFormat(scope.row.job_title_zh, LOCALE)
                    }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$t('seatType')" width="120">
            <template slot-scope="scope">
              <span>{{
                scope.row.booth_user_type == 1
                  ? $t("OnlineSeat")
                  : scope.row.booth_user_type == 2
                  ? $t("OfflineSeat")
                  : "-"
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="drawerFooter">
        <div class="clearfix">
          <el-button
            type="primary"
            size="small"
            class="fr"
            @click="addOnlineChange"
            style="margin-left: 16px; width: 80px"
            :loading="btnloading"
            >{{ $t("confirm") }}</el-button
          >
          <el-button
            size="small"
            class="fr"
            style="width: 80px"
            @click="drawerShow = false"
            >{{ $t("cancel") }}</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import container from "./container";
import boothMixin from "./mixin";
export default {
  components: { container },
  mixins: [boothMixin],
  props: {
    booth_id: {},
  },
  data() {
    return {
      onlineList: [],
      offlineList: [],
      attendList: [],
      drawerTitle: this.$t("addOnlineSeats"),
      drawerShow: false,
      btnloading: false,
      oneLoading: false,
      type: 1,
    };
  },

  watch: {
    booth_id() {
      this.getList();
    },
  },
  mounted() {
    //this.getList();
    this.getAllMeetingPerson();
  },
  methods: {
    async getList() {
      let params = {
        meeting_id: this.MEETING_ID,
        company_id: this.USER_INFO.company_id,
      };
      let result = await this.$store.dispatch(
        "baseConsole/getContactPerson",
        params
      );
      if (result.success) {
        let arr = [];
        let arr1 = [];

        // this.attendList = result.data;
        result.data.filter((it) => {
          if (it.user_type == 1) {
            arr.push(it);
          }
          if (it.user_type == 2) {
            arr1.push(it);
          }
        });
        this.onlineList = arr;
        this.offlineList = arr1;
      }
      this.initComplete();
    },
    // 获取参会人列表
    async getAllMeetingPerson() {
      this.oneLoading = true;
      let params = {
        meeting_id: this.MEETING_ID,
        company_id: this.USER_INFO.company_id,
        audit_status: 1,
        nopage: 1,
      };
      try {
        let result = await this.$store.dispatch(
          "baseConsole/attendeeManage",
          params
        );
        if (result.success) {
          result.data.forEach((it) => {
            it.isCheck = false;
          });
          this.attendList = result.data;
          this.filterData();
        }
      } catch (e) {}
      this.oneLoading = false;
    },
    addOnline() {
      this.drawerTitle = this.$t("addOnlineSeats");
      this.drawerShow = true;
      this.type = 1;
      this.getAllMeetingPerson();
    },
    addOffline() {
      this.drawerTitle = this.$t("addOfflineSeats");
      this.drawerShow = true;
      this.type = 2;
      this.getAllMeetingPerson();
    },
    // 过滤数据
    filterData() {
      this.attendList.forEach((it) => {
        it.isdisabled = false;
        it.isCheck = false;
      });
      this.attendList.forEach((it) => {
        if (it.seats) {
          it.isdisabled = true;
        }
      });
    },
    // 删除坐席
    deleteVideo(item) {
      console.log(item);
      this.$confirm(this.$t("confirmDelete"), this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          let result = await this.$store.dispatch(
            "baseConsole/delContactPerson",
            {
              booth_user_rel_id: item.booth_user_rel_id,
              deleted: 1,
              meeting_id: this.MEETING_ID,
            }
          );
          if (result.success) {
            this.$delTip();
            this.getList();
          }
        })
        .catch(() => {});
    },
    async addOnlineChange() {
      let arr = [];
      this.attendList.filter((it) => {
        if ((this.type == 1 && it.booth_user_type == 1) || it.isCheck) {
          arr.push(it.user_id);
        } else if ((this.type == 2 && it.booth_user_type == 2) || it.isCheck) {
          arr.push(it.user_id);
        }
      });
      let params = {
        meeting_id: this.MEETING_ID,
        company_id: this.USER_INFO.company_id,
      };
      try {
        let result = await this.$store.dispatch(
          "baseConsole/countSeats",
          params
        );
        if (result.success) {
          if (this.type == 1) {
            if (result.data.booth_type != 3) {
              let num = result.data.seats_online_num || result.data.seat_num;
              if (num <= this.onlineList.length || num < arr.length) {
                let seatsOnline =
                  result.data.seats_online_num || result.data.seat_num;
                this.$message.warning(
                  this.$t("addMoreSeat", {
                    seat: seatsOnline,
                    type: this.$t("onlineSeats"),
                  })
                );
                return;
              }
            }
          } else {
            if (result.data.booth_type != 3) {
              let num =
                result.data.seats_offline_num || result.data.seat_num_offline;
              if (num <= this.offlineList.length || num < arr.length) {
                let offlineVal =
                  result.data.seats_offline_num || result.data.seat_num_offline;
                this.$message.warning(
                  this.$t("addMoreSeat", {
                    seat: offlineVal,
                    type: this.$t("offlineSeats"),
                  })
                );
                return;
              }
            } else {
              if (this.offlineList.length >= 1 || arr.length > 1) {
                this.$message.warning(
                  this.$t("addMoreSeat", {
                    seat: 1,
                    type: this.$t("offlineSeats"),
                  })
                );
                return;
              }
            }
          }
          this.confimAddAttende(arr);
        }
      } catch (e) {}
    },
    async confimAddAttende(arr) {
      this.btnloading = true;

      if (arr.length == 0) {
        this.btnloading = false;
        return;
      }
      let params = {
        user_id: arr.join(","),
        user_type: this.type,
        booth_id: this.booth_id,
      };
      console.log(params);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/saveBoothUserInfo",
          params
        );
        if (result.success) {
          this.$ssTip();
          this.drawerShow = false;
          this.getList();
        }
      } catch (e) {
        this.$seTip();
      }
      this.btnloading = false;
    },
    checkValue(row) {
      let index = row.$index;
      this.attendList[index].isCheck = !this.attendList[index].isCheck;
    },
  },
};
</script>
<style lang="less" scoped>
.seatsTitle {
  padding-right: 32px;
  padding-left: 20px;
  height: 35px;
  .fl {
    font-weight: bold;
  }
}
.onlineContent {
  padding-left: 20px;
  margin-bottom: 34px;
  min-height: 120px;
  position: relative;
}
.nodatas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #909399;
}
.onlineItem {
  position: relative;
  width: 200px;
  height: 120px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-right: 24px;
  margin-top: 10px;
  .avatar {
    width: 57px;
    height: 57px;
    margin: 30px 15px;
  }
  .info {
    width: 95px;
    margin: 30px 0;
    div {
      margin-bottom: 5px;
    }
  }
}
.infoTable {
  width: 170px;
  margin: 5px 0;
}
.avatarTable {
  width: 37px;
  height: 37px;
  margin: 10px 8px;
}
.drawerFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
  padding: 30px;
}
.drawerData {
  padding: 0 30px;
}
.delete {
  padding: 1px 15px;
  background: #1f292e;
  border-radius: 11px;
  opacity: 0.8;
  position: absolute;
  right: 2px;
  top: 2px;
  color: #fff !important;
  z-index: 10;
}
</style>